import React from "react"
import Layout from "../components/Layout"
import GatsbyBackgroundImage from "gatsby-background-image"
import { graphql } from "gatsby"
import get from "lodash.get"
import { css } from "@emotion/core"
export default function Game({ data }) {
  const d = get(data, "prismicGame.data")
  return (
    <Layout>
      <div className="collapse-next-section">
        {" "}
        <GatsbyBackgroundImage
          fluid={[
            get(d, "background_image.fluid"),
            "linear-gradient(transparent,transparent)",
          ].filter(Boolean)}
        >
          <section className="mt-0 section-b-0 hero-section-overlay ">
            <div className="   container tablet:py-40  py-56 ">
              <div className=" prose space-y-5 p-opacity-80   text-center">
                <h1>{get(d, "title.text")}</h1>
              </div>
            </div>
          </section>
        </GatsbyBackgroundImage>
      </div>
      <section>
        <div className="container">
          {" "}
          <div
            className="prose-xl max-w-3xl mt-12"
            dangerouslySetInnerHTML={{ __html: get(d, "excerpt.html", "") }}
          ></div>
          <div
            className="prose max-w-3xl mt-12"
            dangerouslySetInnerHTML={{ __html: get(d, "text.html", "") }}
          ></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($uid: String) {
    prismicGame(uid: { eq: $uid }) {
      id
      data {
        background_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
        excerpt {
          html
        }
        text {
          html
        }
      }
    }
  }
`
